//------------------------
// component: SECTION 
// used on homepage
//------------------------

.c-section {
	display: block;
	width: 100%;
	max-width: 800px;
	padding: $spacing-unit--small 0 $spacing-unit;
	@include mq(tablet){
		padding: $spacing-unit--large 0;
	}
}

//---------------
//BANNER

.c-section__banner {
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}

.c-section__banner--bg-left {
	background-repeat: no-repeat;
	background-size: contain;
	background-position: left center;

}

//---------------
//TITLE

.c-section__title {
	font-size: 32px;
	font-family: 'DecimaMonoXLt', monospace;
	line-height: 1;
	margin-bottom: $spacing-unit--small;
	padding: $spacing-unit--small 0;
	color: $color-secondary;
	@include mq(mobile) {
		font-size: 36px;
	}
	@include mq(tablet) {
		font-size: 68px;
		margin-bottom: 0;
		padding: $spacing-unit 0;
	}
}

.c-section__title--large {
	font-size: 40px;
	@include mq(mobile) {
		font-size: 62px;
	}
	@include mq(tablet) {
		font-size: 86px;
	}
}
.c-section__title--small {
	font-size: 30px;
	@include mq(mobile) {
		font-size: 36px;
	}
	@include mq(tablet) {
		font-size: 62px;
	}
}


//---------------
//INFO

.c-section__info {
	@include mq(tablet) {
		margin-top: 20px;
		padding-right: $spacing-unit--huge;
	}
	p {
		margin-bottom: $spacing-unit--small;

		@include mq(tablet) {
			margin-bottom: $spacing-unit;
		}
	}
	b, strong {
		font-weight: weight(sbold);
	}
}
