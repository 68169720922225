/*------------------------------------*\
   UTILITIES -> SPACING RESPONSIVE
\*------------------------------------*/

/**
 * Responsive spacing utility classes
 */

$enable-responsive-margins: false !default;
$enable-responsive-margins--tiny: false !default;
$enable-responsive-margins--small: false !default;
$enable-responsive-margins--large: false !default;
$enable-responsive-margins--huge: false !default;
$enable-responsive-margins--none: false !default;

$enable-responsive-margins-negative: false !default;
$enable-responsive-margins-negative--tiny: false !default;
$enable-responsive-margins-negative--small: false !default;
$enable-responsive-margins-negative--large: false !default;
$enable-responsive-margins-negative--huge: false !default;

$enable-responsive-paddings: false !default;
$enable-responsive-paddings--tiny: false !default;
$enable-responsive-paddings--small: false !default;
$enable-responsive-paddings--large: true;
$enable-responsive-paddings--huge: false !default;
$enable-responsive-paddings--none: false !default;


@if $enable-responsive-margins == true {
   /**
    * Margin helper classes.
    *
    * Add margins.
    */
   @include generate-responsive() {
      .u-m\@#{$generate-responsive-alias} { margin: $margin !important; }
      .u-mt\@#{$generate-responsive-alias} { margin-top: $margin !important; }
      .u-mr\@#{$generate-responsive-alias} { margin-right: $margin !important; }
      .u-mb\@#{$generate-responsive-alias} { margin-bottom: $margin !important; }
      .u-ml\@#{$generate-responsive-alias} { margin-left: $margin !important; }
      .u-mh\@#{$generate-responsive-alias} { margin-right: $margin !important; margin-left: $margin !important; }
      .u-mv\@#{$generate-responsive-alias} { margin-top: $margin !important; margin-bottom: $margin !important; }
   }
}

@if $enable-responsive-margins--tiny == true {
   /**
    * Extra small margins.
    */
   @include generate-responsive() {
      .u-m-xs\@#{$generate-responsive-alias}  { margin: $margin--tiny !important; }
      .u-mt-xs\@#{$generate-responsive-alias} { margin-top: $margin--tiny !important; }
      .u-mr-xs\@#{$generate-responsive-alias} { margin-right: $margin--tiny !important; }
      .u-mb-xs\@#{$generate-responsive-alias} { margin-bottom: $margin--tiny !important; }
      .u-ml-xs\@#{$generate-responsive-alias} { margin-left: $margin--tiny !important; }
      .u-mh-xs\@#{$generate-responsive-alias} { margin-right: $margin--tiny !important; margin-left: $margin--tiny !important; }
      .u-mv-xs\@#{$generate-responsive-alias} { margin-top: $margin--tiny !important; margin-bottom: $margin--tiny !important; }
   }
}

@if $enable-responsive-margins--small == true {
   /**
    * Small margins.
    */
   @include generate-responsive() {
      .u-m-s\@#{$generate-responsive-alias}  { margin: $margin--small !important; }
      .u-mt-s\@#{$generate-responsive-alias} { margin-top: $margin--small !important; }
      .u-mr-s\@#{$generate-responsive-alias} { margin-right: $margin--small !important; }
      .u-mb-s\@#{$generate-responsive-alias} { margin-bottom: $margin--small !important; }
      .u-ml-s\@#{$generate-responsive-alias} { margin-left: $margin--small !important; }
      .u-mh-s\@#{$generate-responsive-alias} { margin-right: $margin--small !important; margin-left: $margin--small !important; }
      .u-mv-s\@#{$generate-responsive-alias} { margin-top: $margin--small !important; margin-bottom: $margin--small !important; }
   }
}

@if $enable-responsive-margins--large == true {
   /**
    * Large margins.
    */
   @include generate-responsive() {
      .u-m-l\@#{$generate-responsive-alias}  { margin: $margin--large !important; }
      .u-mt-l\@#{$generate-responsive-alias} { margin-top: $margin--large !important; }
      .u-mr-l\@#{$generate-responsive-alias} { margin-right: $margin--large !important; }
      .u-mb-l\@#{$generate-responsive-alias} { margin-bottom: $margin--large !important; }
      .u-ml-l\@#{$generate-responsive-alias} { margin-left: $margin--large !important; }
      .u-mh-l\@#{$generate-responsive-alias} { margin-right: $margin--large !important; margin-left: $margin--large !important; }
      .u-mv-l\@#{$generate-responsive-alias} { margin-top: $margin--large !important; margin-bottom: $margin--large !important; }
   }
}

@if $enable-responsive-margins--huge == true {
   /**
    * Extra large margins.
    */
   @include generate-responsive() {
      .u-m-xl\@#{$generate-responsive-alias}  { margin: $margin--huge !important; }
      .u-mt-xl\@#{$generate-responsive-alias} { margin-top: $margin--huge !important; }
      .u-mr-xl\@#{$generate-responsive-alias} { margin-right: $margin--huge !important; }
      .u-mb-xl\@#{$generate-responsive-alias} { margin-bottom: $margin--huge !important; }
      .u-ml-xl\@#{$generate-responsive-alias} { margin-left: $margin--huge !important; }
      .u-mh-xl\@#{$generate-responsive-alias} { margin-right: $margin--huge !important; margin-left: $margin--huge !important; }
      .u-mv-xl\@#{$generate-responsive-alias} { margin-top: $margin--huge !important; margin-bottom: $margin--huge !important; }
   }
}

@if $enable-responsive-margins--none == true {
   /**
    * Remove margins.
    */
   @include generate-responsive() {
      .u-m-0\@#{$generate-responsive-alias}  { margin: 0 !important; }
      .u-mt-0\@#{$generate-responsive-alias} { margin-top: 0 !important; }
      .u-mr-0\@#{$generate-responsive-alias} { margin-right: 0 !important; }
      .u-mb-0\@#{$generate-responsive-alias} { margin-bottom: 0 !important; }
      .u-ml-0\@#{$generate-responsive-alias} { margin-left: 0 !important; }
      .u-mh-0\@#{$generate-responsive-alias} { margin-right: 0 !important; margin-left: 0 !important; }
      .u-mv-0\@#{$generate-responsive-alias} { margin-top: 0 !important; margin-bottom: 0 !important; }
   }
}



@if $enable-responsive-margins-negative == true {
   /**
    * Negative margins.
    */
   @include generate-responsive() {
      .u-m-n\@#{$generate-responsive-alias} { margin: - $margin !important; }
      .u-mt-n\@#{$generate-responsive-alias} { margin-top: - $margin !important; }
      .u-mr-n\@#{$generate-responsive-alias} { margin-right: - $margin !important; }
      .u-mb-n\@#{$generate-responsive-alias} { margin-bottom: - $margin !important; }
      .u-ml-n\@#{$generate-responsive-alias} { margin-left: - $margin !important; }
      .u-mh-n\@#{$generate-responsive-alias} { margin-right: - $margin !important; margin-left: - $margin !important; }
      .u-mv-n\@#{$generate-responsive-alias} { margin-top: - $margin !important; margin-bottom: - $margin !important; }
   }
}

@if $enable-responsive-margins-negative--tiny == true {
   /**
    * Extra small negative margins.
    */
   @include generate-responsive() {
      .u-m-xs-n\@#{$generate-responsive-alias} { margin: - $margin--tiny !important; }
      .u-mt-xs-n\@#{$generate-responsive-alias} { margin-top: - $margin--tiny !important; }
      .u-mr-xs-n\@#{$generate-responsive-alias} { margin-right: - $margin--tiny !important; }
      .u-mb-xs-n\@#{$generate-responsive-alias} { margin-bottom: - $margin--tiny !important; }
      .u-ml-xs-n\@#{$generate-responsive-alias} { margin-left: - $margin--tiny !important; }
      .u-mh-xs-n\@#{$generate-responsive-alias} { margin-right: - $margin--tiny !important; margin-left: - $margin--tiny !important; }
      .u-mv-xs-n\@#{$generate-responsive-alias} { margin-top: - $margin--tiny !important; margin-bottom: - $margin--tiny !important; }
   }
}

@if $enable-responsive-margins-negative--small == true {
   /**
    * Small negative margins.
    */
   @include generate-responsive() {
      .u-m-s-n\@#{$generate-responsive-alias} { margin: - $margin--small !important; }
      .u-mt-s-n\@#{$generate-responsive-alias} { margin-top: - $margin--small !important; }
      .u-mr-s-n\@#{$generate-responsive-alias} { margin-right: - $margin--small !important; }
      .u-mb-s-n\@#{$generate-responsive-alias} { margin-bottom: - $margin--small !important; }
      .u-ml-s-n\@#{$generate-responsive-alias} { margin-left: - $margin--small !important; }
      .u-mh-s-n\@#{$generate-responsive-alias} { margin-right: - $margin--small !important; margin-left: - $margin--small !important; }
      .u-mv-s-n\@#{$generate-responsive-alias} { margin-top: - $margin--small !important; margin-bottom: - $margin--small !important; }
   }
}

@if $enable-responsive-margins-negative--large == true {
   /**
    * Large negative margins.
    */
   @include generate-responsive() {
      .u-m-l-n\@#{$generate-responsive-alias} { margin: - $margin--large !important; }
      .u-mt-l-n\@#{$generate-responsive-alias} { margin-top: - $margin--large !important; }
      .u-mr-l-n\@#{$generate-responsive-alias} { margin-right: - $margin--large !important; }
      .u-mb-l-n\@#{$generate-responsive-alias} { margin-bottom: - $margin--large !important; }
      .u-ml-l-n\@#{$generate-responsive-alias} { margin-left: - $margin--large !important; }
      .u-mh-l-n\@#{$generate-responsive-alias} { margin-right: - $margin--large !important; margin-left: - $margin--large !important; }
      .u-mv-l-n\@#{$generate-responsive-alias} { margin-top: - $margin--large !important; margin-bottom: -$margin--large !important; }
   }
}

@if $enable-responsive-margins-negative--huge == true {
   /**
    * Extra large negative margins.
    */
   @include generate-responsive() {
      .u-m-xl-n\@#{$generate-responsive-alias} { margin: - $margin--huge !important; }
      .u-mt-xl-n\@#{$generate-responsive-alias} { margin-top: - $margin--huge !important; }
      .u-mr-xl-n\@#{$generate-responsive-alias} { margin-right: - $margin--huge !important; }
      .u-mb-xl-n\@#{$generate-responsive-alias} { margin-bottom: - $margin--huge !important; }
      .u-ml-xl-n\@#{$generate-responsive-alias} { margin-left: - $margin--huge !important; }
      .u-mh-xl-n\@#{$generate-responsive-alias} { margin-right: - $margin--huge !important; margin-left: - $margin--huge !important; }
      .u-mv-xl-n\@#{$generate-responsive-alias} { margin-top: - $margin--huge !important; margin-bottom: - $margin--huge !important; }
   }
}



@if $enable-responsive-paddings == true {
   /**
    * Padding helper classes.
    *
    * Add paddings.
    */
   @include generate-responsive() {
      .u-p\@#{$generate-responsive-alias} { padding: $padding !important; }
      .u-pt\@#{$generate-responsive-alias} { padding-top: $padding !important; }
      .u-pr\@#{$generate-responsive-alias} { padding-right: $padding !important; }
      .u-pb\@#{$generate-responsive-alias} { padding-bottom: $padding !important; }
      .u-pl\@#{$generate-responsive-alias} { padding-left: $padding !important; }
      .u-ph\@#{$generate-responsive-alias} { padding-right: $padding !important; padding-left: $padding !important; }
      .u-pv\@#{$generate-responsive-alias} { padding-top: $padding !important; padding-bottom: $padding !important; }
   }
}

@if $enable-responsive-paddings--tiny == true {
   /**
    * Extra small paddings.
    */
   @include generate-responsive() {
      .u-p-xs\@#{$generate-responsive-alias}  { padding: $padding--tiny !important; }
      .u-pt-xs\@#{$generate-responsive-alias} { padding-top: $padding--tiny !important; }
      .u-pr-xs\@#{$generate-responsive-alias} { padding-right: $padding--tiny !important; }
      .u-pb-xs\@#{$generate-responsive-alias} { padding-bottom: $padding--tiny !important; }
      .u-pl-xs\@#{$generate-responsive-alias} { padding-left: $padding--tiny !important; }
      .u-ph-xs\@#{$generate-responsive-alias} { padding-right: $padding--tiny !important; padding-left: $padding--tiny !important; }
      .u-pv-xs\@#{$generate-responsive-alias} { padding-top: $padding--tiny !important; padding-bottom: $padding--tiny !important; }
   }
}

@if $enable-responsive-paddings--small == true {
   /**
    * Small paddings.
    */
   @include generate-responsive() {
      .u-p-s\@#{$generate-responsive-alias}  { padding: $padding--small !important; }
      .u-pt-s\@#{$generate-responsive-alias} { padding-top: $padding--small !important; }
      .u-pr-s\@#{$generate-responsive-alias} { padding-right: $padding--small !important; }
      .u-pb-s\@#{$generate-responsive-alias} { padding-bottom: $padding--small !important; }
      .u-pl-s\@#{$generate-responsive-alias} { padding-left: $padding--small !important; }
      .u-ph-s\@#{$generate-responsive-alias} { padding-right: $padding--small !important; padding-left: $padding--small !important; }
      .u-pv-s\@#{$generate-responsive-alias} { padding-top: $padding--small !important; padding-bottom: $padding--small !important; }
   }
}

@if $enable-responsive-paddings--large == true {
   /**
    * Large paddings.
    */
   @include generate-responsive() {
      .u-p-l\@#{$generate-responsive-alias}  { padding: $padding--large !important; }
      .u-pt-l\@#{$generate-responsive-alias} { padding-top: $padding--large !important; }
      .u-pr-l\@#{$generate-responsive-alias} { padding-right: $padding--large !important; }
      .u-pb-l\@#{$generate-responsive-alias} { padding-bottom: $padding--large !important; }
      .u-pl-l\@#{$generate-responsive-alias} { padding-left: $padding--large !important; }
      .u-ph-l\@#{$generate-responsive-alias} { padding-right: $padding--large !important; padding-left: $padding--large !important; }
      .u-pv-l\@#{$generate-responsive-alias} { padding-top: $padding--large !important; padding-bottom: $padding--large !important; }
   }
}

@if $enable-responsive-paddings--huge == true {
   /**
    * Extra large paddings.
    */
   @include generate-responsive() {
      .u-p-xl\@#{$generate-responsive-alias}  { padding: $padding--huge !important; }
      .u-pt-xl\@#{$generate-responsive-alias} { padding-top: $padding--huge !important; }
      .u-pr-xl\@#{$generate-responsive-alias} { padding-right: $padding--huge !important; }
      .u-pb-xl\@#{$generate-responsive-alias} { padding-bottom: $padding--huge !important; }
      .u-pl-xl\@#{$generate-responsive-alias} { padding-left: $padding--huge !important; }
      .u-ph-xl\@#{$generate-responsive-alias} { padding-right: $padding--huge !important; padding-left: $padding--huge !important; }
      .u-pv-xl\@#{$generate-responsive-alias} { padding-top: $padding--huge !important; padding-bottom: $padding--huge !important; }
   }
}

@if $enable-responsive-paddings--none == true {
   /**
    * Remove paddings.
    */
   @include generate-responsive() {
      .u-p-0\@#{$generate-responsive-alias}  { padding: 0 !important; }
      .u-pt-0\@#{$generate-responsive-alias} { padding-top: 0 !important; }
      .u-pr-0\@#{$generate-responsive-alias} { padding-right: 0 !important; }
      .u-pb-0\@#{$generate-responsive-alias} { padding-bottom: 0 !important; }
      .u-pl-0\@#{$generate-responsive-alias} { padding-left: 0 !important; }
      .u-ph-0\@#{$generate-responsive-alias} { padding-right: 0 !important; padding-left: 0 !important; }
      .u-pv-0\@#{$generate-responsive-alias} { padding-top: 0 !important; padding-bottom: 0 !important; }
   }
}