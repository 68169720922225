///*------------------------------------*\
//    SETTINGS -> Z-INDEX
//\*------------------------------------*/

$z-layers: (
	default: 1,
	below: -1,
	site-head: 6,
	site-nav: 5
);
