//------------------------
// component: HEADING
//------------------------ 

.c-heading {
	@include font-size($heading-sizes-1);
	text-align: center;
	@include mq(tablet) {
		text-align: left;
	}
}


//modifiers

.c-heading--image {
	position: relative;
	text-transform: lowercase;

	img {
		display: inline-block;
		width: 25px;
		height: auto;
		vertical-align: baseline;
		margin-left: $spacing-unit--small;

		@include mq(tablet) {
			vertical-align: baseline;
			width: 40px;
			margin-left: $spacing-unit;
		}
		@include mq(desktop) {
			width: 50px;
			margin-left: $spacing-unit + $spacing-unit--small;
		}
	}

}



.c-heading--ornament {
	&:before {
		display: block;
		content: "";
		width: 1px;
		height: $spacing-unit;
		margin-left: 50%;
		background: $color-secondary;
		margin-top: $spacing-unit--tiny;
		margin-bottom: $spacing-unit--tiny;

	}
	@include mq(tablet) {

		&:before {
			display: none;
		}
	}
}


.c-heading--small {
	@include font-size($heading-sizes-2);
}

.c-heading--hide-on-mobile {
	display: none;
	@include mq(tablet){
		display: block;
	}
}