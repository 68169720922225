//------------------------
// component: BACKGROUND (BG) 
// - must be included after c-site-head
//------------------------



//------------------------
// image background 

.c-bg--image {
	background-repeat: no-repeat;
	background-position: $spacing-unit--small 140px;
	@include mq(tablet){
		background-position: $spacing-unit 275px;
	}
	@include mq(desktop) {
		background-position: $spacing-unit 290px;
	}
}

//modifiers
.c-bg--image--pushed {
	@include mq($until : tablet) {
		background-position: $spacing-unit--small 205px;
	}
}

.c-bg--image--top {
	background-position: $spacing-unit--small $site-head-height;
	@include mq(tablet) {
		background-position: $spacing-unit--small 180px;
	}
}

//------------------------
// white background 

.c-bg--white {
	background-color: rgba( #fff , 0.925);
	@include mq(tablet) {
		background-color: rgba( #fff , 0.925);
	}
}