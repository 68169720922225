//------------------------
// component: DESCRIPTION LIST
// - used on jobs
//------------------------


.c-description-list {
	> dd,
	> dt {
		margin-left: 0;
	}

	> dt {
		font-weight: weight(sbold);
	}

	> dd {
		margin-bottom: $spacing-unit--small;
	}
}

.c-description-list--mb0 {
	> dd {
		margin-bottom: 0;
	}
}

