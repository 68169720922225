//------------------------
// component: BLOCK LINK
// - used on jobs
//------------------------

.c-block-link {
	margin-bottom: $spacing-unit;
	position: relative;
}

.c-block-link__icon {
	position: absolute;
	height: 40px;
	width: 40px;
	top: -10px;
	left: 0;
	background: url('../images/read-more-icon.svg');
	background-size: contain;
	@include mq(tablet) {
		left: -35px;
		height: 25px;
		width: 25px;
		top: -2px;
	}
}

.c-block-link__content {
	margin-left: 55px;
	@include mq(tablet) {
		margin-left: 0;
	}
}