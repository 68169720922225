/*------------------------------------*\
    OBJECTS -> TABLES
\*------------------------------------*/

// Predefine the variables below in order to alter and enable specific features.

$table-padding--compact: quarter($spacing-unit) !default;
$table-padding--cosy: halve($spacing-unit) !default;
$table-padding--comfy: $spacing-unit !default;

$table-border-width: 1px !default;
$table-border-style: solid !default;
$table-border-color: #ccc !default;

$enable-table--fixed: false !default;
$enable-table--compact: false !default;
$enable-table--cosy: false !default;
$enable-table--comfy: false !default;
$enable-table--cells: false !default;
$enable-table--rows: false !default;
$enable-table--columns: false !default;

.o-table,
%o-table {
   width: 100%;
}

@if $enable-table--fixed == true {
   /**
    * Force tables into having equal-width columns.
    */
   .o-table--fixed,
   %o-table--fixed {
      table-layout: fixed;
   }
}

@if $enable-table--compact == true {
   /**
    * Tables with very tightly packed cells.
    */
   .o-table--compact,
   %o-table--compact {
      th,
      td {
         padding: $table-padding--compact;
      }
   }
}

@if $enable-table--cosy == true {
   /**
    * Lightly packed cells.
    */
   .o-table--cosy,
   %o-table--cosy {
      th,
      td {
         padding: $table-padding--cosy;
      }
   }
}

@if $enable-table--comfy == true {
   /**
    * Tables with very widely padded cells.
    */
   .o-table--comfy,
   %o-table--comfy {
      th,
      td {
         padding: $table-padding--comfy;
      }
   }
}

@if $enable-table--cells == true {
   /**
    * Add borders around a `table`.
    */
   .o-table--cells,
   %o-table--cells {
      th,
      td {
         border: $table-border-width $table-border-style $table-border-color;
      }
   }
}

@if $enable-table--rows == true {
   /**
    * Add borders only to `table`'s rows.
    */
   .o-table--rows,
   %o-table--rows {
      &,
      th,
      td {
         border: 0 $table-border-style $table-border-color;
      }
   }

   .o-table--rows,
   %o-table--rows {
      border-top-width: $table-border-width;

      th,
      td {
         border-bottom-width: $table-border-width;
      }
   }
}

@if $enable-table--columns == true {
   /**
    * Add borders only to `table`'s columns.
    */
   .o-table--columns,
   %o-table--columns {
      &,
      th,
      td {
         border: 0 $table-border-style $table-border-color;
      }
   }

   .o-table--columns,
   %o-table--columns {
      border-left-width: $table-border-width;

      th,
      td {
         border-right-width: $table-border-width;
      }
   }
}