/*------------------------------------*\
   BASE -> LINKS
\*------------------------------------*/

a {
    color: $color-links;
    text-decoration: none;
    transition: $global-transition;

    @include on-event {
        color: $color-links-hover;
    }
}