//------------------------
// component: ACCORDION  
// - must be included after c-title-link: it extends the component
//------------------------


.c-accordion {
	padding: $spacing-unit--small 0;
}

.c-accordion__title {
	@extend %c-title-link;
	
	@include mq(tablet) {
		&:after {
			opacity: 1;
		}
	}
	
	.is-open & {
		&:after {
			background: url('../images/minimize-icon.svg');					
			background-size: contain;
			background-repeat: no-repeat;
		}
	}
}

.c-accordion__title--image {
	img {
		max-width: 120px;
	}
}

.c-accordion__expand {
	display: none;
	.is-open & {
		display: block;
	}
}


.c-accordion__title--image img.partner-logo 
{
	max-width: none;
	max-height: 40px;
	width: auto;
	height: auto;
}

.c-accordion__title--image img.partner-logo.logo-small
{
	max-height: 30px;
}
