//------------------------
// component: FORM
//------------------------

.c-form {
	max-width: 400px;
	margin-bottom: $spacing-unit;
	@include mq(tablet) {
		margin-bottom: $spacing-unit--large;
	}
	input[type="text"], textarea {
		border: none;
	}
}

.c-form__btn {
	padding: $spacing-unit--tiny $spacing-unit;
	margin-bottom: $spacing-unit;
	width: 180px;
}

.c-form__error {
	color: $color-brand;
	text-transform: uppercase;
	display: none;
}

.c-form__success {
	margin-top: $spacing-unit--large;
	min-height: 300px;
}