/*------------------------------------*\
    UTILITIES -> FLOATS
\*------------------------------------*/

/**
 * Simply make an element floated or remove it.
 *
 * All the utilities (available as silent placeholder selectors also):
 *
   .u-fl
   .u-fr
   .u-fn
 *
*/

$enable-floats-responsive: true;

// Float left

.u-fl,
%u-fl {
   float: left !important;
}

// Float right

.u-fr,
%u-fr {
   float: right !important;
}

// Float none

.u-fn,
%u-fn {
   float: none !important;
}

// Responsive floats

@if $enable-floats-responsive == true {
   @include generate-responsive {

      .u-fl\@#{$generate-responsive-alias},
      %u-fl\@#{$generate-responsive-alias} {
         float: left !important;
      }

      .u-fr\@#{$generate-responsive-alias},
      %u-fr\@#{$generate-responsive-alias} {
         float: right !important;
      }

      .u-fn\@#{$generate-responsive-alias},
      %u-fn\@#{$generate-responsive-alias} {
         float: none !important;
      }
   }
}