/*------------------------------------*\
    OBJECTS -> CONTAINERS
\*------------------------------------*/

$containers: (
    null: 1400px
);

$containers-spacing: (
    base: $spacing-unit--small,
    tablet: $spacing-unit
);

.o-container {
    margin: 0 auto;
    padding-left: map-fetch($containers-spacing, base);
    padding-right: map-fetch($containers-spacing, base);

    @include mq(tablet) {
        padding-left: map-fetch($containers-spacing, tablet);
        padding-right: map-fetch($containers-spacing, tablet);
    }
}

@each $container-alias, $container-size in $containers {
    .o-container#{$container-alias} {
        max-width: $container-size + (map-fetch($containers-spacing, tablet) * 2);
    }
}