
.blog-teaser
{
	@include mq(tablet){
		padding-bottom: $spacing-unit--large;
	}
}

a.blog-teaser-title h2:hover
{
	color: $color-links;
}

.blog-article h1
{
	font-size: 36px;
	text-transform: none;
}

.blog-header
{
	margin-bottom: 30px;
}

.blog-teaser-title h2
{
	font-size: 36px;
	text-transform: none;
}

.blog-article li
{
    margin-bottom: 15px;
}
