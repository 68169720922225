//------------------------
// component: MOBILE TITLE
//------------------------

.c-mobile-title {
	position: relative;
	width: 100%;
	font-family: $font-family-base;
	font-size: 16px;
	text-transform: uppercase;
	text-align: center;
	font-weight: weight(sbold);
	color: $color-brand;
	margin-bottom: 0;
	&:after {
		display: block;
		content: "";
		width: 1px;
		height: $spacing-unit;
		background: $color-secondary;
		margin-left: 50%;
		margin-top: $spacing-unit--tiny;
		margin-bottom: $spacing-unit--tiny;
	}

	@include mq(tablet){
		display: none;
	}
}

.c-mobile-title--no-ornament {
	&:after {
		display: none;
	}
}

.c-mobile-title--small {
	font-size: 14px;
	color: $color-secondary;
}