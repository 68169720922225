//------------------------
// component: TITLE LINK
//------------------------

.c-title-link,
%c-title-link  {
	display: block;
	width: 100%;
	position: relative;
	padding-right: $spacing-unit--large;
	color: $color-secondary;
	font-family: $font-family-headings;
	line-height: 1.2;
	padding-top: $spacing-unit--tiny;
	padding-bottom: $spacing-unit--tiny;
	&:after {
		color: transparent;
		content: "";
		display: block;
		height: 40px;
		width: 40px;
		background: url('../images/read-more-icon.svg');
		background-size: contain;
		background-repeat: no-repeat;
		position: absolute;
		top: 50%;
		margin-top: -20px;
		right: 0;
		opacity: 1;
	}
	@include mq(tablet) {
		padding: 0;
		&:after {
			right: auto;
			left: -35px;
			height: 25px;
			width: 25px;
			margin-top: -13px;
			opacity: 0;
		}
	}

	@include on-event {
		outline: none;
		color: $color-brand;
		&:after {
			opacity: 1;
		}
	}
	
}

.c-title-link--image {
	img {
		width: 120px;
		height: auto;
	}
}


.c-title-link--dark-hover {
	@include on-event {
		color: $color-secondary;
	}
}