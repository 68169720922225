//------------------------
// component: MAIN
// - wraps content
//------------------------


.c-main {
	position: relative;
	padding-top: $site-head-height;
	min-height: calc(100% - 233px);
	@include mq(mobile) {
		min-height: calc(100% - 176px);
	}
	@include mq(tablet) {
		padding-top: 170px;
		min-height: calc(100% - 117px);
	}
	@include mq(desktop) {
		min-height: calc(100% - 81px);
	}
}

.lang a, .lang span
{
	display: inline;
	margin-right: 5px;
}

.c-btn--sol
{
	margin-right: 35px;
}

.tag-cloud
{
	margin-top: -15px;
}

.tag-cloud a
{
	font-size: 12px;
	margin-right: 4px;
	pointer-events: none;
	cursor: default;
}
