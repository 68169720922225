//------------------------
// component: NAV TRIGGER
//------------------------

.c-burger-menu {
  width: 32px;
  height: 30px;
  position: relative;
  margin: 0 auto;
  margin-top: 14px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

.c-burger-menu span {
  display: block;
  position: absolute;
  height: 4px;
  width: 50%;
  background: $color-brand;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.c-burger-menu span:nth-child(even) {
  left: 50%;
  border-radius: 0 5px 5px 0;
}

.c-burger-menu span:nth-child(odd) {
  left:0px;
  border-radius: 5px 0 0 5px;
}

.c-burger-menu span:nth-child(1), .c-burger-menu span:nth-child(2) {
  top: 0px;
}

.c-burger-menu span:nth-child(3), .c-burger-menu span:nth-child(4) {
  top: 10px;
}

.c-burger-menu span:nth-child(5), .c-burger-menu span:nth-child(6) {
  top: 20px;
}

.c-burger-menu.is-active span:nth-child(1),.c-burger-menu.is-active span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.c-burger-menu.is-active span:nth-child(2),.c-burger-menu.is-active span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.c-burger-menu.is-active span:nth-child(1) {
  left: 5px;
  top: 7px;
}

.c-burger-menu.is-active span:nth-child(2) {
  left: calc(50% - 5px);
  top: 7px;
}

.c-burger-menu.is-active span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

.c-burger-menu.is-active span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

.c-burger-menu.is-active span:nth-child(5) {
  left: 5px;
  top: 13px;
}

.c-burger-menu.is-active span:nth-child(6) {
  left: calc(50% - 5px);
  top: 13px;
}
