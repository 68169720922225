///*------------------------------------*\
//    TOOLS -> PLACEHOLDER COLOR
//\*------------------------------------*/

/**
 * A mixin that allows you to override the default browser colour for the text
 * input `placeholder` attribute. A default colour is set in Core -> Settings
 * -> Colours.
 *
 * N.B. by default Firefox sets its placeholder attribute to have an opacity
 * of '0.4' so we override that to be '1'.
 *
 * @example
   .foo {
     @include text-input-placeholder-color();
   }

   .foo {
     @include text-input-placeholder-color(#666);
   }
 */

@mixin placeholder-color($color: $color-text-input-placeholder) {
   // Webkit
   &::-webkit-input-placeholder {
      color: $color;
   }

   // Firefox 19+
   &::-moz-placeholder {
      color: $color;
      opacity: 1;
   }

   // IE
   &:-ms-input-placeholder {
      color: $color;
   }

   // Standard
   &:input-placeholder {
      color: $color;
   }

   &::input-placeholder {
      color: $color;
   }
}