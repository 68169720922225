/*------------------------------------*\
    OBJECTS -> LIST-BARE
\*------------------------------------*/

/**
 * The list-bare object simply removes any indents and bullet points from lists.
 */

@mixin list-bare {
    margin: 0;
    padding: 0;
    list-style: none;
}

.o-list-bare,
%o-list-bare {
    @include list-bare;
}