///*------------------------------------*\
//    SETTINGS -> TYPOGRAPHY
//\*------------------------------------*/

// Base

$font-size-base: 14px;
$line-height-base: 21px;

// Font Families

$font-formats: woff eot ttf;

@include scut-font-face("DecimaMonoX", "../fonts/DecimaMonoX", normal, $formats: $font-formats);
@include scut-font-face("DecimaMonoXLt", "../fonts/DecimaMonoXLt", 300, $formats: $font-formats);


$font-family-base: 'Open Sans' , sans-serif;
$font-family-headings: 'DecimaMonoX', monospace;

// Font weights

$font-weights: (
  light: 300,
	normal: 400,
	sbold: 600
);

// Headings

$heading-sizes-1: (
  null: 36px,
  mobile: 40px,
  tablet: 54px,
  desktop: 72px
);

$heading-sizes-2: (
  null: 24px,
  mobile: 28px,
  tablet: 36px,
  desktop: 40px
);

$heading-sizes-3: (
  null: 21px,
  mobile: 24px
);

$heading-sizes-4: (
  null: 18px
);

$heading-sizes-5: (
  null: 16px
);

$heading-sizes-6: (
  null: 14px
);
