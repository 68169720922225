/*------------------------------------*\
   UTILITIES -> SPACING
\*------------------------------------*/

/**
 * Spacing utility classes
 */

$margin: $spacing-unit;
$margin--tiny: $spacing-unit--tiny;
$margin--small: $spacing-unit--small;
$margin--large: $spacing-unit--large;
$margin--huge: $spacing-unit--huge;

$padding: $spacing-unit;
$padding--tiny: $spacing-unit--tiny;
$padding--small: $spacing-unit--small;
$padding--large: $spacing-unit--large;
$padding--huge: $spacing-unit--huge;

$enable-margins: true;
$enable-margins--tiny: false !default;
$enable-margins--small: false !default;
$enable-margins--large: false !default;
$enable-margins--huge: false !default;
$enable-margins--none: false !default;

$enable-margins-negative: false !default;
$enable-margins-negative--tiny: false !default;
$enable-margins-negative--small: false !default;
$enable-margins-negative--large: false !default;
$enable-margins-negative--huge: false !default;

$enable-paddings: true;
$enable-paddings--tiny: false !default;
$enable-paddings--small: false !default;
$enable-paddings--large: false !default;
$enable-paddings--huge: false !default;
$enable-paddings--none: false !default;


@if $enable-margins == true {
   /**
    * Margin helper classes.
    *
    * Add margins.
    */
   .u-m { margin:  $margin !important; }
   .u-mt { margin-top:  $margin !important; }
   .u-mr { margin-right:  $margin !important; }
   .u-mb { margin-bottom:  $margin !important; }
   .u-ml { margin-left:  $margin !important; }
   .u-mh { margin-right:  $margin !important; margin-left:  $margin !important; }
   .u-mv { margin-top:  $margin !important; margin-bottom:  $margin !important; }
}

@if $enable-margins--tiny == true {
   /**
    * Add extra small margins.
    */
   .u-m-xs { margin:  $margin--tiny !important; }
   .u-mt-xs { margin-top:  $margin--tiny !important; }
   .u-mr-xs { margin-right:  $margin--tiny !important; }
   .u-mb-xs { margin-bottom:  $margin--tiny !important; }
   .u-ml-xs { margin-left:  $margin--tiny !important; }
   .u-mh-xs { margin-right:  $margin--tiny !important; margin-left:  $margin--tiny !important; }
   .u-mv-xs { margin-top:  $margin--tiny !important; margin-bottom:  $margin--tiny !important; }
}

@if $enable-margins--small == true {
   /**
    * Add small margins.
    */
   .u-m-s { margin: $margin--small !important; }
   .u-mt-s { margin-top: $margin--small !important; }
   .u-mr-s { margin-right: $margin--small !important; }
   .u-mb-s { margin-bottom: $margin--small !important; }
   .u-ml-s { margin-left: $margin--small !important; }
   .u-mh-s { margin-right: $margin--small !important; margin-left: $margin--small !important; }
   .u-mv-s { margin-top: $margin--small !important; margin-bottom: $margin--small !important; }
}

@if $enable-margins--large == true {
   /**
    * Add large margins.
    */
   .u-m-l { margin: $margin--large !important; }
   .u-mt-l { margin-top: $margin--large !important; }
   .u-mr-l { margin-right: $margin--large !important; }
   .u-mb-l { margin-bottom: $margin--large !important; }
   .u-ml-l { margin-left: $margin--large !important; }
   .u-mh-l { margin-right: $margin--large !important; margin-left: $margin--large !important; }
   .u-mv-l { margin-top: $margin--large !important; margin-bottom: $margin--large !important; }
}

@if $enable-margins--huge == true {
   /**
    * Add extra large margins.
    */
   .u-m-xl { margin:  $margin--huge !important; }
   .u-mt-xl { margin-top:  $margin--huge !important; }
   .u-mr-xl { margin-right:  $margin--huge !important; }
   .u-mb-xl { margin-bottom:  $margin--huge !important; }
   .u-ml-xl { margin-left:  $margin--huge !important; }
   .u-mh-xl { margin-right:  $margin--huge !important; margin-left:  $margin--huge !important; }
   .u-mv-xl { margin-top:  $margin--huge !important; margin-bottom:  $margin--huge !important; }
}

@if $enable-margins--none == true {
   /**
    * Remove margins
    */
   .u-m-0 { margin: 0 !important; }
   .u-mt-0 { margin-top: 0 !important; }
   .u-mr-0 { margin-right: 0 !important; }
   .u-mb-0 { margin-bottom: 0 !important; }
   .u-ml-0 { margin-left: 0 !important; }
   .u-mh-0 { margin-right: 0 !important; margin-left: 0 !important; }
   .u-mv-0 { margin-top: 0 !important; margin-bottom: 0 !important; }
}



@if $enable-margins-negative == true {
   /**
    * Add negative margins.
    */
   .u-m-n { margin: - $margin !important; }
   .u-mt-n { margin-top: - $margin !important; }
   .u-mr-n { margin-right: - $margin !important; }
   .u-mb-n { margin-bottom: - $margin !important; }
   .u-ml-n { margin-left: - $margin !important; }
   .u-mh-n { margin-right: - $margin !important; margin-left: - $margin !important; }
   .u-mv-n { margin-top: - $margin !important; margin-bottom: - $margin !important; }
}

@if $enable-margins-negative--tiny == true {
   /**
    * Add negative extra small margins.
    */
   .u-m-xs-n { margin: - $margin--tiny !important; }
   .u-mt-xs-n { margin-top: - $margin--tiny !important; }
   .u-mr-xs-n { margin-right: - $margin--tiny !important; }
   .u-mb-xs-n { margin-bottom: - $margin--tiny !important; }
   .u-ml-xs-n { margin-left: - $margin--tiny !important; }
   .u-mh-xs-n { margin-right: - $margin--tiny !important; margin-left: - $margin--tiny !important; }
   .u-mv-xs-n { margin-top: - $margin--tiny !important; margin-bottom: - $margin--tiny !important; }
}

@if $enable-margins-negative--small == true {
   /**
    * Add negative small margins.
    */
   .u-m-s-n { margin: - $margin--small !important; }
   .u-mt-s-n { margin-top: - $margin--small !important; }
   .u-mr-s-n { margin-right: - $margin--small !important; }
   .u-mb-s-n { margin-bottom: - $margin--small !important; }
   .u-ml-s-n { margin-left: - $margin--small !important; }
   .u-mh-s-n { margin-right: - $margin--small !important; margin-left: - $margin--small !important; }
   .u-mv-s-n { margin-top: - $margin--small !important; margin-bottom: - $margin--small !important; }
}

@if $enable-margins-negative--large == true {
   /**
    * Add negative large margins.
    */
   .u-m-l-n { margin: - $margin--large !important; }
   .u-mt-l-n { margin-top: - $margin--large !important; }
   .u-mr-l-n { margin-right: - $margin--large !important; }
   .u-mb-l-n { margin-bottom: - $margin--large !important; }
   .u-ml-l-n { margin-left: - $margin--large !important; }
   .u-mh-l-n { margin-right: - $margin--large !important; margin-left: - $margin--large !important; }
   .u-mv-l-n { margin-top: - $margin--large !important; margin-bottom: - $margin--large !important; }
}

@if $enable-margins-negative--huge == true {
   /**
    * Add negative extra large margins.
    */
   .u-m-xl-n { margin: - $margin--huge !important; }
   .u-mt-xl-n { margin-top: - $margin--huge !important; }
   .u-mr-xl-n { margin-right: - $margin--huge !important; }
   .u-mb-xl-n { margin-bottom: - $margin--huge !important; }
   .u-ml-xl-n { margin-left: - $margin--huge !important; }
   .u-mh-xl-n { margin-right: - $margin--huge !important; margin-left: - $margin--huge !important; }
   .u-mv-xl-n { margin-top: - $margin--huge !important; margin-bottom: - $margin--huge !important; }
}


@if $enable-paddings == true {
   /**
    * Psdding helper classes.
    *
    * Add paddings.
    */
   .u-p { padding: $padding !important; }
   .u-pt { padding-top: $padding !important; }
   .u-pr { padding-right: $padding !important; }
   .u-pb { padding-bottom: $padding !important; }
   .u-pl { padding-left: $padding !important; }
   .u-ph { padding-right: $padding !important; padding-left: $padding !important; }
   .u-pv { padding-top: $padding !important; padding-bottom: $padding !important; }
}

@if $enable-paddings--tiny == true {
   /**
    * Add extra small paddings.
    */
   .u-p-xs { padding: $padding--tiny !important; }
   .u-pt-xs { padding-top: $padding--tiny !important; }
   .u-pr-xs { padding-right: $padding--tiny !important; }
   .u-pb-xs { padding-bottom: $padding--tiny !important; }
   .u-pl-xs { padding-left: $padding--tiny !important; }
   .u-ph-xs { padding-right: $padding--tiny !important; padding-left: $padding--tiny !important; }
   .u-pv-xs { padding-top: $padding--tiny !important; padding-bottom: $padding--tiny !important; }
}

@if $enable-paddings--small == true {
   /**
    * Add small paddings.
    */
   .u-p-s { padding: $padding--small !important; }
   .u-pt-s { padding-top: $padding--small !important; }
   .u-pr-s { padding-right: $padding--small !important; }
   .u-pb-s { padding-bottom: $padding--small !important; }
   .u-pl-s { padding-left: $padding--small !important; }
   .u-ph-s { padding-right: $padding--small !important; padding-left: $padding--small !important; }
   .u-pv-s { padding-top: $padding--small !important; padding-bottom: $padding--small !important; }
}

@if $enable-paddings--large == true {
   /**
    * Add large paddings.
    */
   .u-p-l { padding: $padding--large !important; }
   .u-pt-l { padding-top: $padding--large !important; }
   .u-pr-l { padding-right: $padding--large !important; }
   .u-pb-l { padding-bottom: $padding--large !important; }
   .u-pl-l { padding-left: $padding--large !important; }
   .u-ph-l { padding-right: $padding--large !important; padding-left: $padding--large !important; }
   .u-pv-l { padding-top: $padding--large !important; padding-bottom: $padding--large !important; }
}

@if $enable-paddings--huge == true {
   /**
    * Add extra large paddings.
    */
   .u-p-xl { padding: $padding--huge !important; }
   .u-pt-xl { padding-top: $padding--huge !important; }
   .u-pr-xl { padding-right: $padding--huge !important; }
   .u-pb-xl { padding-bottom: $padding--huge !important; }
   .u-pl-xl { padding-left: $padding--huge !important; }
   .u-ph-xl { padding-right: $padding--huge !important; padding-left: $padding--huge !important; }
   .u-pv-xl { padding-top: $padding--huge !important; padding-bottom: $padding--huge !important; }
}

@if $enable-paddings--none == true {
   /**
    * Remove paddings
    */
   .u-p-0 { padding: 0 !important; }
   .u-pt-0 { padding-top: 0 !important; }
   .u-pr-0 { padding-right: 0 !important; }
   .u-pb-0 { padding-bottom: 0 !important; }
   .u-pl-0 { padding-left: 0 !important; }
   .u-ph-0 { padding-right: 0 !important; padding-left: 0 !important; }
   .u-pv-0 { padding-top: 0 !important; padding-bottom: 0 !important; }
}