//------------------------
// component: MOBILE NAV
//------------------------


$mobile-nav-list-sizes: (
	null: 24px,
	mobile: 28px,
	tablet: 32px
);


.c-mobile-nav {
	width: 100%;
	height: 100%;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	position: fixed;
	background: rgba($color-secondary, 0.95);
	left: 0;
	top: 0;
	opacity: 0;
	z-index: z(below);
	transition: $global-transition;
	display: none;
	
	&.is-open {
		opacity: 1;
		z-index: z(site-nav);
	}
}

.c-mobile-nav__inner {
	display: table;
	height: 100%;
	width: 100%;
}

.c-mobile-nav__content {
	display: table-cell;
	vertical-align: middle;
	padding-top: $site-head-height + $spacing-unit;
	padding-bottom: $site-head-height + $spacing-unit;
	text-align: center;

	@include remove-last-child-margin;
}

.c-mobile-nav__list {
	@include list-bare;

	li,
	a {
		display: block;
	}

	li {
		&:before {
			display: block;
			content: "";
			height:30px;
			margin-top: $spacing-unit--tiny;
			margin-bottom: $spacing-unit--tiny;
			width: 1px;
			background: #fff;
			margin-left: 50%;
		}
		&:first-child {
			&:before {
				display: none;
			}
		}

		&.active a {
			color: $color-brand;
		}
	}

	a {
		@include font-size($mobile-nav-list-sizes);
		color: #fff;
		text-transform: uppercase;
		transition: $global-transition;

		@include on-event {
			color: $color-links-hover;
		}	

	}
}

.c-mobile-nav__buttons {
	width: 100%;
	position: absolute;
	bottom: $spacing-unit--small;
	left: auto;
	a {
		padding: $spacing-unit--small;
		position: relative;
		color: #fff;
		font-size: 16px;
		@include on-event {
			color: $color-links-hover;
		}
		&.is-active {
			color: $color-brand;
		}
		& + a {
			&:before {
			display: inline-block;
			content: "";
			position: absolute;
			left: 0;
			top: 50%;
			height: 14px;
			width: 1px;
			margin-top: -7px;
			margin-left: -2px;
			background: #999;
			}
		}
	}
}