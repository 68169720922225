/*------------------------------------*\
    OBJECTS -> LAYOUT
\*------------------------------------*/

// Predefine the variables below in order to alter and enable specific features.
$layout-gutter: $spacing-unit !default;
$layout-gutter--tiny: quarter($layout-gutter) !default;
$layout-gutter--small: halve($layout-gutter) !default;
$layout-gutter--large: double($layout-gutter) !default;
$layout-gutter--huge: quadruple($layout-gutter) !default;

$enable-layout--tiny: false !default;
$enable-layout--small: false !default;
$enable-layout--large: false !default;
$enable-layout--huge: false !default;
$enable-layout--flush: false !default;
$enable-layout--rev: false !default;
$enable-layout--middle: false !default;
$enable-layout--bottom: false !default;
$enable-layout--right: false !default;
$enable-layout--center: false !default;
$enable-layout--auto: false !default;



.o-layout,
%o-layout {
   margin-left: -$layout-gutter;
   letter-spacing: -0.31em;
   text-rendering: optimizespeed;
   font-family: FreeSans, Arimo, "Droid Sans", Helvetica, Arial, sans-serif;
}

/* Opera as of 12 on Windows needs word-spacing.
   The ".opera-only" selector is used to prevent actual prefocus styling
   and is not required in markup.
*/

.opera-only :-o-prefocus,
.o-layout {
   word-spacing: -0.43em;
}

.o-layout__item,
%o-layout__item {
   display: inline-block;
   letter-spacing: normal;
   word-spacing: normal;
   vertical-align: top;
   text-rendering: auto;
   padding-left: $layout-gutter;
   width: 100%;
   font-family: $font-family-base;
}

@if $enable-layout--tiny == true {
   .o-layout--tiny {
      margin-left: -$layout-gutter--tiny;

      > .o-layout__item,
      > %o-layout__item {
         padding-left: $layout-gutter--tiny;
      }
   }
}

@if $enable-layout--small == true {
   .o-layout--small {
      margin-left: -$layout-gutter--small;

      > .o-layout__item,
      > %o-layout__item {
         padding-left: $layout-gutter--small;
      }
   }
}

@if $enable-layout--large == true {
   .o-layout--large {
      margin-left: -$layout-gutter--large;

      > .o-layout__item,
      > %o-layout__item {
         padding-left: $layout-gutter--large;
      }
   }
}

@if $enable-layout--huge == true {
   .o-layout--huge {
      margin-left: -$layout-gutter--huge;

      > .o-layout__item,
      > %o-layout__item {
         padding-left: $layout-gutter--huge;
      }
   }
}

@if $enable-layout--flush == true {
   .o-layout--flush {
      margin-left: 0;

      > .o-layout__item,
      > %o-layout__item {
         padding-left: 0;
      }
   }
}

@if $enable-layout--rev == true {
   /**
    * Reversed rendered order of layout items, e.g. items 1, 2, 3, 4 in your
    * markup will display in order 4, 3, 2, 1 on your page.
    */
   .o-layout--rev,
   %o-layout--rev {
      direction: rtl;
      text-align: left;

      > .o-layout__item,
      > %o-layout__item {
         direction: ltr;
         text-align: left;
      }
   }
}

@if $enable-layout--middle == true {
   /**
    * Align layout items to the vertical centers of each other.
    */
   .o-layout--middle,
   %o-layout--middle {
      > .o-layout__item,
      > %o-layout__item {
         vertical-align: middle;
      }
   }
}

@if $enable-layout--bottom == true {
   /**
    * Align layout items to the vertical bottoms of each other.
    */
   .o-layout--bottom,
   %o-layout--bottom {
      > .o-layout__item,
      > %o-layout__item {
         vertical-align: bottom;
      }
   }
}

@if $enable-layout--right == true {
   /**
    * Make the layout items fill up from the right hand side.
    */
   .o-layout--right,
   %o-layout--right {
      text-align: right;

      > .o-layout__item,
      > %o-layout__item {
         text-align: left;
      }
   }
}

@if $enable-layout--center == true {
   /**
    * Make the layout items fill up from the center outward.
    */
   .o-layout--center,
   %o-layout--center {
      text-align: center;

      > .o-layout__item,
      > %o-layout__item {
         text-align: left;
      }
   }
}

@if $enable-layout--auto == true {
   /**
    * Cause layout items to take up a non-explicit amount of width.
    */
   .o-layout--auto,
   %o-layout--auto {
      > .o-layout__item,
      > %o-layout__item {
         width: auto;
      }
   }
}
