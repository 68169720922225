//------------------------
// component: SITE FOOT
// - used on footer
//------------------------


.c-site-foot {
    background: $color-secondary;
    color: map-fetch($grayscale, light);
    padding-top: $spacing-unit;
    padding-bottom: $spacing-unit--tiny;
    float: left;
    width: 100%;
    @include mq(tablet) {
        padding-bottom: $spacing-unit;
    }
}


.c-site-foot__info {
    float: left;
    width: 100%;
    @include mq(mobile) {
        margin-bottom: $spacing-unit;

    }
    @include mq(tablet) {
        width: 85%;
        margin-bottom: 0;
    }

    p, a {
        display: block;
        margin-bottom: $spacing-unit--small;
        @include mq(desktop) {
            display: inline-block;
            margin-right: 20px;
            margin-bottom: 0;
        }
    }

    a {
        color: #fff;
        @include on-event {
            color: $color-links;
        }
    }
}

.c-site-foot__group {
    vertical-align: top;
    width: 100%;
    @include mq(mobile){
        display: inline-block;
        width: 40%;
        padding-right: $spacing-unit;
        & + .c-site-foot__group {
            width: 58%;
            padding-right: 0;
            padding-left: $spacing-unit;
            border-left: 1px solid #999; 
        }
        > *:last-child {
            margin-bottom: 0;
        }
    }
    @include mq(tablet) {
        width: auto;
    }
    @include mq(desktop) {
        padding-right: 0;
        & + .c-site-foot__group {
            border-left: none;
            padding-left: 0;
        }
    }
}

.c-site-foot__buttons {
	float: right;
    vertical-align: baseline;
    a {
        display: inline-block;
        position: relative;
        color: #fff;
        padding: $spacing-unit--small;
        @include mq(tablet) {
            padding-top: 0;
            padding-bottom: 0;
        }
       
        & + a {
            margin-left: $spacing-unit--small;
            &:before {
                display: inline-block;
                content: "";
                height: 10px;
                width: 1px;
                background: #999; 
                position: absolute;
                top: 50%;
                left: -$spacing-unit--tiny;
                margin-top: -5px;
                @include mq(desktop) {
                    margin-left: -2px;
                }
            }
        }
        &.is-active {
            color: $color-brand;
        }

        @include on-event {
            color: $color-links-hover;
        }

    }
    
	
}