///*------------------------------------*\
//    TOOLS -> FONT SIZES
//\*------------------------------------*/

// A mixin to spit out our responsive font sizes.
// Create a map for font sizes:
// $h1-font-sizes: (
//   null  : (15px, 1.3),
//   mobile : 17px,
//   tablet: 18px, inherit)
// );
// Call the mixin
// h1 {
//   @include font-size($h1-font-sizes);
// }
//
// N.B - Define the font sizes in px, they will automatically
//       be transformed to rem.

@mixin font-size($fs-map, $fs-breakpoints: $mq-breakpoints) {
  @each $fs-breakpoint, $fs-font-size in $fs-map {
    @if $fs-breakpoint == null {
      @include make-font-size($fs-font-size);
    }
    @else {
      // If $fs-font-size is a key that exists in
      // $fs-breakpoints, use the value
      @if map-has-key($fs-breakpoints, $fs-breakpoint) {
        $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
      }
      @include mq($fs-breakpoint) {
      // @media screen and (min-width: $fs-breakpoint) {
        @include make-font-size($fs-font-size);
      }
    }
  }
}

// Utility function for mixin font-size
@mixin make-font-size($fs-font-size) {
  // If $fs-font-size is a list, include
  // both font-size and line-height
  @if type-of($fs-font-size) == "list" {
    font-size: nth($fs-font-size, 1);
    @if (length($fs-font-size) > 1) {
      line-height: nth($fs-font-size, 2);
    }
  }
  @else {
    font-size: $fs-font-size;
  }
}