///*------------------------------------*\
//    SETTINGS -> COLORS
//\*------------------------------------*/

$grayscale: (
	base: #666,
	dark: #28273A,
	light: #ccc,
	xlight: #eee
);

$palette: (
	brand: (
		base: #E92E46
	)
);

// Base

$color-page-background: #fff;
$color-text-base: map-fetch($grayscale, base);

// Brand
$color-brand: map-fetch($palette, brand, base);
$color-secondary: map-fetch($grayscale, dark);

// Links
$color-links: $color-brand;
$color-links-hover: darken( $color-links , 10% );

// Borders
$color-border: map-fetch($grayscale, base);

// Input placeholder
$color-text-input-placeholder: $color-text-base;

