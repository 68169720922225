/*!
 ***************************************
 *
 * ECKO
 *
 * Copyright 2016
 *
 ***************************************
 */

// -------------------------------------
//  FOLDERS NAMING
// -------------------------------------

// SETTINGS
// Global variables, site-wide settings, config switches, etc.
//
// TOOLS
// Site-wide functions and mixins.
//
// OBJECTS
// Objects, abstractions, and design patterns (e.g. .o-layout {}).
//
// COMPONENTS
// Discrete, complete chunks of UI (e.g. .c-section {}).
//
// SCOPES
// Scoped contexts, CMS generated  (e.g. s-cms-content) 
//
// UTILITIES
// High-specificity, very explicit selectors. Overrides and helper classes (e.g. .u-hidden {}).
//
// VENDOR
// External components, libraries or frameworks. e.g. slider css
//
// VENDOR EXTENDS
// Extends and overwrites of the vendor components.
//
// WORDPRESS
// WordPress specific classes.

// -------------------------------------
//   NAMESPACES
// -------------------------------------

// For more clarity we employ class namespaces:
// more info http://csswizardry.com/2015/03/more-transparent-ui-code-with-namespaces/
//
// Objects: o-
// Components: c-
// Utilities: u-
// Scopes: s-
// Themes: t-
// States: is-, has-
// Js hooks: js-

// -------------------------------------
//   IMPORT FILES
// -------------------------------------

//   Tools - Functions
// -------------------------------------
@import "tools/functions";
@import "vendor/tools/scut";

//   Settings
// -------------------------------------

@import "settings/global";
@import "settings/responsive";
@import "settings/typography";
@import "settings/colors";
@import "settings/spacings";
@import "settings/easing";
@import "settings/zindex";

//   Tools
// -------------------------------------

@import "tools/generate-responsive";
@import "tools/generate-at-breakpoints";
@import "tools/nudge";
@import "tools/font-icon";
@import "tools/font-sizes";
@import "tools/target-headings";
@import "tools/placeholder-color";
@import "tools/on-event";
@import "tools/when-inside";
@import "tools/remove-last-child-margin";

//   Vendor - Tools
// -------------------------------------

@import "vendor/tools/mq";
@import "vendor/normalize";

//   Base
// -------------------------------------

@import "base/page";
@import "base/reset";
@import "base/shared";
@import "base/links";
@import "base/forms";
@import "base/images";
@import "base/headings";
@import "base/hr";
@import "base/print";

//   Vendor
// -------------------------------------


//   Vendor Extends
// -------------------------------------


//   Objects
// -------------------------------------

@import "objects/o-layout";
@import "objects/o-containers";
//@import "objects/o-media";
//@import "objects/o-flag";
@import "objects/o-buttons";
@import "objects/o-tables";
@import "objects/o-list-bare";
@import "objects/o-scaling-svg";

//   Components
// -------------------------------------

@import "components/c-icon-font";
@import "components/c-headings";
@import "components/c-nav-list";
@import "components/c-buttons";
@import "components/c-title-link";
@import "components/c-burger-menu";
@import "components/c-site-head";
@import "components/c-site-foot";
@import "components/c-mobile-nav";
@import "components/c-main";
@import "components/c-accordion";
@import "components/c-bg";
@import "components/c-page-content";
@import "components/c-description-list";
@import "components/c-block-link";
@import "components/c-mobile-title";
@import "components/c-section";
//@import "components/c-scrollspy";
@import "components/c-form";
@import "components/blog";

//   WordPress
// -------------------------------------

//@import "wordpress/alignments";
//@import "wordpress/gallery";
//@import "wordpress/caption";

//   Utilities
// -------------------------------------

@import "utilities/u-widths";
@import "utilities/u-spacing";
@import "utilities/u-spacing-responsive";
@import "utilities/u-headings";
@import "utilities/u-floats";
@import "utilities/u-hide";
@import "utilities/u-show";
@import "utilities/u-text-align";
@import "utilities/u-screen-reader";
@import "utilities/u-colors";
@import "utilities/u-typography";
