/*------------------------------------*\
    UTILITIES -> FLOATS
\*------------------------------------*/

/**
 * Adjust text alignment on an element.
 *
 * All the utilities (available as silent placeholder selectors also):
 *
   .u-tal
   .u-tar
   .u-tac
 *
*/

$enable-text-align-responsive: false !default;

// Float left

.u-tal,
%u-tal {
   text-align: left !important;
}

// Float right

.u-tar,
%u-tar {
   text-align: right !important;
}

// Float none

.u-tac,
%u-tac {
   text-align: center !important;
}

// Responsive floats

@if $enable-text-align-responsive == true {
   @include generate-responsive {

      .u-tal\@#{$generate-responsive-alias},
      %u-tal\@#{$generate-responsive-alias} {
         text-align: left !important;
      }

      .u-tar\@#{$generate-responsive-alias},
      %u-tar\@#{$generate-responsive-alias} {
         text-align: right !important;
      }

      .u-tac\@#{$generate-responsive-alias},
      %u-tac\@#{$generate-responsive-alias} {
         text-align: none !important;
      }
   }
}