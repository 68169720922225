/*------------------------------------*\
   UTILITIES -> HIDE
\*------------------------------------*/

/**
 * Utilities for hiding or showing elements
 *
 * All the utilities (available as silent placeholder selectors also):
 *
   .u-hide
   .u-hide-invisible
   .u-hide-visually
*/

$enable-hide-responsive: true;
$enable-hide-invisible-responive: false !default;
$enable-hide-visually-responsive: false !default;

/**
 * Hide elements from both screen readers and the document flow.
 */

.u-hide,
%u-hide {
   display: none !important;
}

@if $enable-hide-responsive == true {
   @include generate-responsive {
      .u-hide\@#{$generate-responsive-alias},
      %u-hide\@#{$generate-responsive-alias} {
         display: none !important;
      }
   }
}

/**
 * Hide elements without affecting the document flow.
 */

%u-hide-invisible,
.u-hide-invisible {
   visibility: hidden !important;
}

@if $enable-hide-invisible-responive == true {
   @include generate-responsive {
      .u-hide-invisible\@#{$generate-responsive-alias},
      %u-hide-invisible\@#{$generate-responsive-alias} {
         visibility: hidden !important;
      }
   }
}

/**
 * Hide elements only visually but have it available for screen readers.
 */

%u-hide-visually,
.u-hide-visually {
  @include scut-hide-visually;
}

@if $enable-hide-visually-responsive == true {
   @include generate-responsive {
      .u-hide-visually\@#{$generate-responsive-alias},
      %u-hide-visually\@#{$generate-responsive-alias} {
         @include scut-hide-visually;
      }
   }
}
