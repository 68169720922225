/*------------------------------------*\
   UTILITIES -> SCREEN READER
\*------------------------------------*/

// Screen reader utility classes

.u-screen-reader-text,
%u-screen-reader-text {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.u-screen-reader-text--visible-focus {
    &:focus {
        clip: auto !important;
        display: block;
        height: auto;
        left: 5px;
        top: 5px;
        width: auto;
        z-index: 100000; /* Above WP toolbar. */
    }
}