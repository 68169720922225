/*------------------------------------*\
    UTILITIES -> HEADINGS
\*------------------------------------*/

/**
 * stranded heading hierarchy: csswizardry.com/2012/02/pragmatic-practical-font-sizing-in-css
 *
 * Use these helper classes to cause other elements to adopt the styling of the
 * respective heading, e.g.:
 *
   <h2 class="u-alpha">Lorem ipsum</h2>
 *
 */

.u-h1,
%u-h1 {
   @include font-size($heading-sizes-1);
}

.u-h2,
%u-h2 {
   @include font-size($heading-sizes-2);
}

.u-h3,
%u-h3 {
   @include font-size($heading-sizes-3);
}

.u-h4,
%u-h4 {
   @include font-size($heading-sizes-4);
}

.u-h5,
%u-h5 {
   @include font-size($heading-sizes-5);
}

.u-h6,
%u-h6 {
   @include font-size($heading-sizes-6);
}
