@mixin generate-at-breakpoints($respond-to: all, $map: $mq-breakpoints ) {
    @content;

    @if $respond-to == "all" {
        $respond-to: map-keys($map);
    }
    @each $breakpoint in $respond-to {
        @if map-has-key($map, $breakpoint) {
            @include mq($breakpoint, $breakpoints: $map) {
                &\@#{$breakpoint} {
                    @content;
                }
            }
        }
        @else {
            @warn "Breakpoint '#{$breakpoint}' does not exist in map";
        }
    }

}
