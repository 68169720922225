//------------------------
// component: PAGE CONTENT
//------------------------


.c-page-content {
	width: 100%;
	position: relative;
	padding-bottom: $spacing-unit;
	@include mq(tablet) {
		padding-left: 100px;
		
		.c-heading {
			margin-left: -100px;
		}

		.c-heading--aligned {
			margin-left: 0;
		}
	}

	@include mq(desktop) {
		padding-left: 200px;

		.c-heading  {
			margin-left: -200px;
		}

		.c-heading--aligned {
			margin-left: 0;
		}
	}
}