/*------------------------------------*\
  UTILITIES -> WIDTHS
\*------------------------------------*/

// Define the width fractions
$widths: (
    12
);

// Offset items by a certain width.
// Leave value equal to "null" if they're not needed.
$width-offsets: (
    12
);

// Whole units
.u-1-of-1 {
    @include generate-at-breakpoints {
        width: 100% !important;
    }
}

@include generate-responsive() {
    @each $denominator in $widths {
        @for $numerator from 1 through $denominator {
            @if $numerator != $denominator {
                .u-#{$numerator}-of-#{$denominator}\@#{$generate-responsive-alias} {
                    width: calc(100% * (#{$numerator}/#{$denominator})) !important;
                }

                // Generate offsets
                @if $width-offsets != null {

                    // Push
                    .u-push-#{$numerator}-of-#{$denominator}\@#{$generate-responsive-alias} {
                        position: relative;
                        right: auto;
                        left: calc(100% * (#{$numerator}/#{$denominator})) !important;
                    }

                    // Pull
                    .u-pull-#{$numerator}-of-#{$denominator}\@#{$generate-responsive-alias} {
                        position: relative;
                        left: auto;
                        right: calc(100% * (#{$numerator}/#{$denominator})) !important;
                    }
                }
            }
        }
    }
}
