//------------------------
// component: SITE HEADER
// - used on header
//------------------------


$site-head-height: 75px;

.c-site-head {
    position: fixed;
    left: 0;
    width: 100%;
    z-index: z(site-head);
    transition: $global-transition;
    height: $site-head-height;
    @include mq(tablet) {
        padding-top:  60px;
    }

    &.is-scrolling {
        background: rgba(#fff, 0);
        padding-top: 0;
        @include mq(desktop){
            transform: translateY(-$site-head-height);
	    transition-duration: 0.3s;
        }
    }

    &.is-active {
        background: rgba(#fff, 1);
        transform: translateY(0);
        @include mq(tablet){
            padding-top: 10px;
        }    
        @include mq(desktop) {
            background: rgba(#fff, 0.9);
	    border-bottom: 1px solid #e4e4e4;
//	    transition: border 0ms ease-out;
        }
    }

}

.c-site-head__logo {
    display: block;
    width: 75px;
    float: left;
    margin-top: 25px;
    transition: $global-transition;
    opacity: 1;

    @include mq(tablet) {
        width: 131px;
        margin-top: 0;
        .is-active & {
            width: 90px;
            margin-top: 10px;
            &:hover {
                width: 95px;
            }
        }
    }

    .is-scrolling & {
        opacity: 0;
        @include mq(desktop) {
            opacity: 1;
        }
    }
    .is-active & {
        opacity: 1;
    }
}

.c-site-head__trigger {
    display: block;
    float: right;
    width: 50px;
    height: 50px;
    background: rgba(#fff , 0.8);
    position: relative;
    margin-top: 12px;
    opacity: 1;
    @include mq(tablet) {
        margin-top: 0;
    }
    @include mq(desktop) {
        display: none;
        width: 32px;
        .c-site-head.is-active.is-scrolling & {
            display: none;
        }
    }

    .c-site-head.is-scrolling & {
        display: block;
        margin-top: 10px;
        @include mq(desktop) {
            margin-top: $site-head-height + 10px;
        }
    }

    .c-site-head.is-active & {
        margin-top: 12px;
        @include mq(tablet) {
            margin-top: 2px;
        }
    }
}

.c-site-head__nav {
    float: right;
    margin-top: $spacing-unit--small;
    display: none;
    margin-right: 32px;
    
    @include mq(desktop) {
        display: inline-block;
    }
    
    .c-site-head.is-active.is-active & {
        @include mq(desktop){
	    margin-right: 32px;
	}
    }
    
    .c-site-head.is-scrolling & {
        @include mq(desktop){
	    margin-right: 0px;
	}
    }
}
