//------------------------
// component: BUTTONS 
//------------------------

.c-btn {
    @extend %o-btn;

    position: relative;
    text-transform: uppercase;
    transition: 0.2s;
    z-index: 1;
    border: 1px solid $color-secondary;
    background: transparent;
    color: $color-secondary;
    padding: $spacing-unit--tiny $spacing-unit--small;
    font-weight: weight(sbold);

    @include on-event {
        background: $color-secondary;
        color: #fff;
    }
    
}

// modifiers

.c-btn--fill {
    border-color: $color-secondary;
    background: #fff;
    color: $color-secondary;
    @include on-event {
        border-color: $color-secondary;
        background: $color-secondary;
        color: $color-brand;
    }
}


.c-btn--icon {
    padding-left: 45px;
    padding-right: 0;
    border: none;
    @include on-event {
      color: $color-brand;
      background: transparent;
    }
    &:before {
        display: block;
        content: '';
        background-size: contain;
        vertical-align: middle;
        position: absolute;
        height: 35px;
        width: 35px;
        top: 0;
        left: 0;
        background-image: url('../images/arrow-left-icon.svg');
    }
    @include mq(tablet) {
        padding-left: 35px;
        padding-top: 0;
        &:before {
            top: -2px;
            height: 25px;
            width: 25px;
        }
    }
}

//icon modifier classes must be included along with c-btn--icon class

.c-btn--plus {
    &:before {
        background-image: url('../images/read-more-icon.svg');
    }
}

.c-btn--back {
    text-transform: none;
    &:before {
        background-image: url('../images/back-icon.svg');
    }
}