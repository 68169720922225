//------------------------
// component: ICON FONT
// font is generated with icomoon:  https://icomoon.io/app , 
// and included in ../fonts. this is the css associated with that font
//------------------------ 

@font-face {
    font-family: 'ecko-icons';
    src:url('../fonts/ecko-icons.eot');
    src:url('../fonts/ecko-icons.eot?#iefixi6sjqe') format('embedded-opentype'),
        url('../fonts/ecko-icons.ttf') format('truetype'),
        url('../fonts/ecko-icons.woff') format('woff'),
        url('../fonts/ecko-icons.svg#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="c-icon-"], [class*="c-icon-"] {
   @include font-icon('ecko-icons');
}

.c-icon-close-menu:before {
  content: "\e900";
}
.c-icon-menu:before {
  content: "\e909";
}
.c-icon-arrow-right:before {
  content: "\e901";
}
.c-icon-arrow-left:before {
  content: "\e902";
}
.c-icon-close:before {
  content: "\e903";
}
.c-icon-arrow-up:before {
  content: "\e904";
}
.c-icon-arrow-down:before {
  content: "\e905";
}
.c-icon-plus:before {
  content: "\e906";
}
.c-icon-minus:before {
  content: "\e907";
}
.c-icon-checkmark:before {
  content: "\e908";
}

