/*------------------------------------*\
  BASE -> PAGE
\*------------------------------------*/

/**
 * The root element: `html`.
 */

$apply-osx-font-smoothing: true !default;

/**
 * 1. Set the default `font-size`, `line-height` , `font-family` and `color` for the
 *    entire project.
 * 2. Friendly box sizing
 * 3. Fonts on OSX will look more consistent with other systems that do not
 *    render text using sub-pixel anti-aliasing.
 */

html {
   font-size: ($font-size-base / 16px) * 1em; // [1]
   line-height: $line-height-base / $font-size-base; // [1]
   font-family: $font-family-base; // [1]
   color: $color-text-base;
   background: $color-page-background;
   box-sizing: border-box; // [2]
   min-height: 100%;
   height: 100%;

   @if $apply-osx-font-smoothing == true {
      -moz-osx-font-smoothing: grayscale; // [3]
      -webkit-font-smoothing: antialiased; // [3]
   }
}

* {

   &,
   &:before,
   &:after {
      box-sizing: inherit; // [2]
   }
}

body.is-locked {
   overflow: hidden;
}

body {
   height: 100%;
}
