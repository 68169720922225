///*------------------------------------*\
//    SETTINGS -> GLOBAL
//\*------------------------------------*/

// Variables used globally

// Radius
$global-border-radius: 0;

// Transitions
$global-transition: 0.3s;

