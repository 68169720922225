.u-color-white {
	color: #fff !important;
}


.u-color-text {
	color: $color-text-base !important;
	
}
a.u-color-text{
	@include on-event {
		color: $color-brand !important;
	}
}

.u-color-brand {
	color: $color-brand !important;
}

.u-color-secondary {
	color: $color-secondary !important;
}