/*------------------------------------*\
    BASE -> HEADINGS
\*------------------------------------*/

/**
 * Headings 1–6.
 */

// Predefine the variables below in order to alter and enable specific features.
// Depends on Settings -> Responsive

@include target-headings {
   font-family: $font-family-headings;
   font-weight: normal;
   font-feature-settings: normal;
   color: $color-secondary;
}

@include target-headings(1, 3) {
    line-height: 1.2;
}

h1 {
   @include font-size($heading-sizes-1);
}

h2 {
   @include font-size($heading-sizes-2);
}

h3 {
   @include font-size($heading-sizes-3);
}

h4 {
   @include font-size($heading-sizes-4);
}

h5 {
   @include font-size($heading-sizes-5);
}

h6 {
   @include font-size($heading-sizes-6);
}

