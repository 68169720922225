/*------------------------------------*\
    BASE -> FORMS
\*------------------------------------*/

/**
 * Settings.
 */

/**
 * Text inputs and `select`s styles.
 */

$form-text-color: $color-secondary;
$form-background-color: map-fetch($grayscale, xlight) !default;

$form-padding: 20px;
$form-margin-bottom: $spacing-unit;

$form-border-thickness: 1px !default;
$form-border-style: solid !default;
$form-border-color: map-fetch($grayscale, xlight) !default;

$form-border-radius: $global-border-radius !default;

$form-disabled-state-opacity: 0.6 !default;

/**
 * The name of the class to target iOS so the top inner shadow from iOS inputs
 * can be removed.
 */

/**
 * Box sizing.
 *
 * Here we set a variable assuming that `box-sizing: border-box;` is not set
 * globally. If it has been previously been defined, the following variable
 * will be overridden and will be set to `true`.
 */

/**
 * Indicate that `label` will shift focus to the associated `input` element.
 */

label {
   cursor: pointer;
}

/**
 * Disable `textarea`s from being resized horizontally.
 */

textarea {
   resize: vertical;
}

/**
 * Override `content-box` from normalize.css for search inputs.
 */

input[type="search"] {
   box-sizing: border-box;
}

/**
 * Remove rounded corners from iOS search inputs by overriding
 * `appearance: textfield` from normalize.css.
 * See: https://github.com/necolas/normalize.css/issues/360.
 */

input[type="search"] {
   appearance: none;
}

/**
 * Remove rounded corners that iOS applies to all `input` buttons, see:
 * https://github.com/necolas/normalize.css/issues/178. And certain browsers
 * e.g. Chrome, apply rounded corners to `select` lists so if we haven't opted
 * in for rounded corners via the settings.
 */
input,
input[type="submit"],
input[type="button"],
input[type="image"],
input[type="reset"],
select {
   border-radius: 0;
}

/**
 * Fix for IE and old versions of some other browsers not wrapping text within
 * a `legend`.
 *
 * 1. Enable line-wrapping in IE8+.
 * 2. Enable line-wrapping in old versions of some other browsers.
 *
 * @credit
 * http://www.456bereastreet.com/archive/201210/how_to_line_wrap_text_in_legend_elements_even_in_ie/
 */

legend {
   display: table; // [1]
   white-space: normal; // [2]
}

/**
 * Disabled state.
 *
 * N.B. it is okay to use `!important` here as we're doing it preemptively
 * i.e. you know you will always want the rule it's applied too to take
 * precedence.
 */

button[disabled],
input[disabled],
select[disabled],
textarea[disabled] {
   cursor: not-allowed !important;
   text-shadow: none !important;
   box-shadow: none !important;
   opacity: $form-disabled-state-opacity !important;
}

/**
 * Text inputs , `textarea`s, and `select` lists.
 */
input , 
input[type="search"],
textarea,
select {
   padding: $form-padding;
   color: $form-text-color;
   background: $form-background-color;
   border: $form-border-thickness $form-border-style $form-border-color;
   width: 100%;
   margin-bottom: $form-margin-bottom;

   @if $form-border-radius != 0 {
      border-radius: $form-border-radius;
   }

   &:focus {
      outline: none;
   }
}

/**
 * Text input placeholder colour which uses the
 * `text-input-placeholder-color()` mixin.
 */

@include placeholder-color;

