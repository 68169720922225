/*------------------------------------*\
   UTILITIES -> SHOW
\*------------------------------------*/

/**
 * Utilities for showing elements
 *
 * All the utilities (available as silent placeholder selectors also):
 *
   .u-show
   .u-show-block
*/

$enable-show-responsive: true;

/**
 * show elements that are hidden with display:none 
 */

.u-show,
%u-show {
   display: inline-block !important;
}

@if $enable-show-responsive == true {
   @include generate-responsive {
      .u-show\@#{$generate-responsive-alias},
      %u-show\@#{$generate-responsive-alias} {
         display: inline-block !important;
      }
   }
}


.u-show-block,
%u-show-block {
   display: block !important;
}

@if $enable-show-responsive == true {
   @include generate-responsive {
      .u-show-block\@#{$generate-responsive-alias},
      %u-show-block\@#{$generate-responsive-alias} {
         display: block !important;
      }
   }
}

