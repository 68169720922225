///*------------------------------------*\
//    SETTINGS -> SPACINGS
//\*------------------------------------*/

$spacing-unit        : 30px;
$spacing-unit--tiny  : round($spacing-unit / 4);
$spacing-unit--small : round($spacing-unit / 2);
$spacing-unit--large : round($spacing-unit * 2);
$spacing-unit--huge  : round($spacing-unit * 4);


