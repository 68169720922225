/*------------------------------------*\
   BASE -> HORIZONTAL RULE
\*------------------------------------*/

$hr-color:  map-fetch($grayscale, light) !default;
$hr-style: solid !default;
$hr-width: 1px !default;
$hr-border: $hr-width $hr-style $hr-color !default;

hr {
   display: block;
   border: 0;
   border-top: $hr-border;
   padding: 0;
}