//------------------------
// component: NAV LIST
//------------------------


.c-nav-list {
	@extend %o-list-bare;
	li {
		display: inline-block;
		& + li {
			margin-left: $spacing-unit;
		}
		&.active a {
			border-color: $color-secondary;
			color: $color-brand;
		}
	}
	a {
		padding: 12px 20px;
		border: 2px solid transparent;
		color: $color-secondary;
		font-weight: weight(sbold);
		text-transform: uppercase;

		@include on-event {
			border-color: $color-secondary;
			color: $color-brand;
		}
		
	}

}