.o-scaling-svg {
    display: block;
    position: relative;
    height: 0;
    width: 100%;
    padding: 0;
    padding-bottom: 100%; // overwrite this inline

    > svg {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
    }
}

//needs inline style="padding-bottom: (100xheight/width)%"